@import "./variables.scss";

.static_input {
  background-color: #f1f0f09a;
  border-bottom: 1px solid rgb(174, 174, 174);
  color: #a3a3a3;
  margin: 2vh 0 2vh 0;
  padding: 1vh 2vw 1vh 2vw;

  .title {
    font-size: 3.3vw;
    padding-bottom: 0.5vh;
  }
}

.inputTextField {
  h5 {
    margin: 0;
    font-weight: 500;
    font-size: 4vw;
  }

  input,
  textarea {
    background-color: #f5f5f5 !important;
    border: none !important;
    border-bottom: 1px solid #737373 !important;
    margin: 0vh 0 2vh 0;
    padding: 1.7vh 2vw 1.7vh 3vw;
    width: 100%;
    font-weight: 400;
    color: black;
    font-size: 4.1vw;
  }

  textarea {
    border: 3px solid rgba(0, 0, 0, 0.18);
    padding-left: 1vh;
    border-radius: 10px;
    height: 5vh;
    margin-top: 1vh;
  }

  input::placeholder {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.535);
  }

  input:focus {
    outline: none;
    border-bottom: 3px solid #0f69b3;
  }

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .preffixComponent {
    padding-right: 1vw;
    color: $darkGrey;
  }
}

.css-b62m3t-container {
  width: 100%;
}

.inputSelectField {
  h5 {
    margin-bottom: 2.5vw;
  }
}

.inputTextField-haserror-true {
  input {
    border-bottom: red 3px solid;
  }
}

.input-text-error {
  color: red;
  margin-top: -1vh;
  font-size: 2.8vw;
  margin-bottom: 2vh;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-1jqq78o-placeholder {
  padding-left: 1vw;
}

.css-qbdosj-Input {
  display: none !important;
}

.css-13cymwt-control {
  background-color: #f5f5f5 !important;
  border: none !important;
  border-bottom: 1px solid #737373 !important;
  margin-bottom: 2vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 0vw;
}

.css-1jqq78o-placeholder {
  color: black;
  font-size: 4.1vw;
}

.css-1fdsijx-ValueContainer {
  padding-left: 0 !important;
}
