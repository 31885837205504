.advice {
  background: #fee2e2;
  border-radius: 15px;
  padding: 2vh 6vw;

  .header {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 2vh;
    color: red;

    img {
      margin-right: 3vw;
    }
  }

  p {
    text-align: center;
    color: red;
  }
}

.vinculate_email_screen {
  background-color: white;
  height: 100vh;
  padding: 2vh 6vw;
  text-align: center;

  > img {
    width: 50%;
  }
}

.vinculated_success_modal {
  padding-top: 8vh;

  > img {
    width: 70%;
  }

  .bold {
    margin-top: 8vh;
    margin-bottom: 2vh;
  }
}

.bold {
  font-weight: bold;
}
