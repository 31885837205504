@import "./variables.scss";

.camera-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .footer-camera {
    margin-top: 4vh;
  }

  .web-cam-content {
    position: relative;
    .liveness-indicator-content {
      width: 100%;
      height: 100%;
      top: 0;
      position: absolute;

      .square {
        width: 15px;
        height: 15px;
        background-color: $primaryColor;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 5px;
      }
    }
  }
}

.image_cutter {
  background-color: white;
  height: 110vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 8vw 5vh 8vw;
  margin-bottom: 5vh;

  h2 {
    color: $darkBlue;
    text-align: center;
    font-size: 5vw;
    margin-bottom: 4vh;
  }

  .rotate-buttons {
    display: flex;
    justify-content: center;
    margin-top: 3vh;

    i {
      background-color: $primaryColor;
      font-size: 6vw;
      padding: 3vw;
      border-radius: 8px;
      margin-left: 2vw;
      margin-right: 2vw;
    }
  }
  .button {
    margin-top: 5vh;
    width: 100%;
  }
}

.styles_CameraButtons__TakePhoto_39jWdDEN3p_AXFhDJy9o5u {
  left: 0;
  right: 0;
  margin: auto;
  top: 90%;
  z-index: 500;
  position: absolute;
  background-image: url("../icons/obturator.svg");
  width: 15vw;
  background-color: transparent;
  height: 15vw;
  background-size: cover;
  border: none;
  color: transparent;
}

.styles_CameraButtons__ChangeCamera_1VNIrUIH17QDyB-BF0OqYb {
  display: none;
}
