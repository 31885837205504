@import "./variables.scss";

.button {
  background-color: $primaryColor;
  border: none;
  color: white;
  border-radius: 30px;
  padding: 1.5vh 2vw;
  text-align: center;
  border: 2px solid $primaryColor;
  font-family: "Nunito";
  font-weight: bold;
  font-size: 4vw;
}

.button:hover {
  //background-color: $primaryColorHover;
  cursor: pointer;
}

.button_outlined_true {
  background-color: transparent !important;
  color: $primaryColor;
}

.button_disabled_true {
  background-color: #bfe6f0;
  border-color: #bfe6f0;
}
