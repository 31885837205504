@import "./variables.scss";

.liveness_challenge_content {
  background-color: white;
  width: 100vw;
  height: 93vh;
  margin: 0;
  padding: 0;
  overflow-y: hidden;

  h4 {
    text-align: center;
    font-size: 4.4vw;
  }
  .button {
    width: 96vw;
    margin-top: 1vh;
  }

  .overlay {
    width: 100vw;
    height: 93vh;
    object-fit: cover;
    position: absolute;
    z-index: 2;
  }

  .liveness_challenge_layer_one {
    background: #00000052;
    position: fixed;
    padding-top: 2vh;
    width: 100vw;
    height: 98vh;
    top: 0;
    left: 0;
  }

  .liveness_challenge_layer_two {
    padding: 0vw 6vw 0 4vw;
  }

  p {
    position: absolute;
    left: 0;
    color: white;
    bottom: 19%;
    width: 100vw;
  }

  .container-button {
    position: absolute;
    left: 2vw;
    bottom: 10%;
  }

  @supports (-webkit-touch-callout: none) {
    p {
      bottom: 32%;
    }
    .container-button {
      position: absolute;
      bottom: 25%;
    }
    .liveness_challenge_layer_one {
      height: 107vh;
    }
  }

  @media only screen and (min-width: 800px) {
    h4 {
      font-size: 1.4vw;
    }
    .container-button {
      display: flex;
      justify-content: center;
    }
    .button {
      margin-top: 5vh;
      width: 45vw;
    }
  }
}

.FaceSquareIndicator {
  height: 85%;
  width: 80%;
  background-color: transparent;
  border: 2vw solid #2bbfed91;
  position: absolute;
  border-radius: 5px;
  top: 0;
  bottom: 0;
  left: 8%;
  margin: auto;
}

.camera-container {
  padding-top: 7vh;
}

.liveness_time_indicator {
  color: white;
  background: black;
  position: absolute;
  top: 10%;
  left: 0;
  padding-left: 4%;
  padding-right: 1%;
}
