body {
  background-color: white;
  font-family: "Open Sans", sans-serif;
}
body,
html {
  height: 100%;
  width: 100%;
}

*,
* {
  transition: all 0.5s ease;
  padding: 0;
  margin: 0;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center_text {
  text-align: center;
}

.opacity-zero {
  opacity: 0;
}

.zero-width {
  width: 0;
  height: 0;
}

.image-preview {
  position: relative;
}

.image-preview-close {
  background-color: white;
  width: 7vw;
  border-radius: 3.5vw;
  height: 7vw;
  position: absolute;
  top: 5%;
  right: 15%;
}

.fullwidth {
  width: 100%;
}

.colorred {
  color: red !important;
}

.modal-full-height {
  .react-modal-sheet-container {
    height: 100vh !important;
  }
}

.react-file-reader {
  width: 97.6% !important;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
