@import "./variables.scss";

.intro_verification_identity {
  display: flex;
  flex-direction: column;
  padding: 5vh 8vw 5vh 8vw;

  img {
    width: 40vw;
    margin: auto;
  }

  h2 {
    color: $darkBlue;
    text-align: center;
    font-size: 6vw;
    margin-top: 4vh;
  }

  p {
    margin-top: 4vh;
    text-align: center;
  }

  .button {
    margin-top: 4vh;
  }
}

.intro_verification_facial {
  padding: 2vh 8vw 5vh 8vw;
}

.verification_identity_screen {
  display: flex;
  flex-direction: column;
  padding: 5vh 8vw 15vh 8vw;

  h2 {
    color: $darkBlue;
    text-align: center;
    font-size: 5vw;
    margin-bottom: 4vh;
  }

  > img {
    margin-bottom: 4vh;
  }

  .document-image {
    width: 100%;
  }

  .button {
    margin-top: 2vh;
  }
}

.identity_instruction {
  display: grid;
  margin-bottom: 2vh;
  grid-template-columns: 10% auto;

  p {
    font-size: 4vw;
  }
}

.verification_identity_screen_finished {
  i {
    position: absolute;
    right: 8vw;
    font-size: 6vw;
    top: 2vh;
  }
  h2 {
    text-align: center;
    margin-bottom: 2vh;
    font-size: 5vw;
  }

  p {
    text-align: center;
    margin-bottom: 4vh;
  }
}

.verification_identity_screen_finished_true {
  h2 {
    color: green;
  }

  p {
    color: green;
  }
}

.verification_identity_screen_finished_false {
  h2 {
    color: red;
  }

  p {
    color: red;
  }
}

.camera_file_input { 
  position: absolute;
  z-index: 2;
  opacity: 0;
  margin-top: 2vh;
  width: 100%;
  padding: 1.8vh 2vw;
  box-sizing: border-box;
}

.take_photo_button_container {
  position: relative;

  .camera_file_input { 
    position: absolute;
    z-index: 2;
    opacity: 0;
    margin-top: 2vh;
    width: 100%;
    padding: 1.8vh 2vw;
    box-sizing: border-box;
  }

  .button {
    z-index: 1 !important;
    width: 100% !important;
  }
}

.identity_camera {
  background-color: black;
  height: 101vh;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100vw;

  .overlay_container {
    width: 100vw;
    min-height: 100vh;
    /* background-image: url("../ilustrations/documentmask.png");
    background-repeat: no-repeat;
    background-size: 101%;
    background-repeat: initial;
    background-position: center; */
    position: fixed;
    top: 0;
    left: 0;

    .borders {
      border: 2px dashed red;
      border-radius: 15px;
      margin: auto;
      margin-top: 5vw;
      width: 90vw;
      height: 70vh;

      p {
        color: white;
        font-weight: bold;
        position: fixed;
        width: 155vw;
        top: 36vh;
        left: 9vw;
        text-align: center;

        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
      }
    }
  }

  .obturator_icon {
    width: 15vw;
    left: 0;
    right: 0;
    margin: auto;
    top: 90%;
    position: absolute;
  }
}

.verification_identity_review {
  .row-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 5vh;

    .button {
      width: 95%;
    }

    .separator {
      width: 4vw;
      height: 1px;
    }
  }
}

#react-select-3-listbox > div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.verification_identity_select_country {
  padding-bottom: 15vh;

  .ilustration {
    margin-bottom: 6vh;
    text-align: center;

    img {
      width: 70%;
    }
  }

  > p {
    margin-bottom: 4vh;
  }

  .verf_identity_select_country {
    margin-bottom: 4vh;

    h3 {
      color: #737373;
      margin-bottom: 2vh;
    }

    .custom_option_country {
      background-color: #f5f5f5;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 3vw 4vw;

      img {
        margin-right: 5vw;
        width: 7vw;
      }
    }

    .custom_option_country_control {
      border-radius: 15px;
      justify-content: space-between;
      padding: 3vw 2vw;

      img {
        margin-right: 2vw;
      }

      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  .verf_identity_type_document {
    margin-bottom: 5vh;

    h3 {
      color: #737373;
      margin-bottom: 2vh;
    }

    .verfIdentityTypeDocumentItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .checkbox {
        margin-right: 3vw;
      }

      p {
        text-transform: lowercase;
      }

      p::first-letter {
        text-transform: capitalize;
      }
    }
  }
}
