@import "./variables.scss";

.LayoutVerification {
  padding: 3vw 6vw 0 4vw;

  position: relative;
  height: 100vh;

  .VerificationsFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5vw;
    padding-bottom: 3vw;

    h5 {
      margin-top: 4vw;
      color: $primaryColor;
      font-weight: 700;
    }

    .version_number {
      margin-top: 4vw;
      margin-bottom: 2vw;
      font-size: 3vw;
      font-style: italic;
    }
  }
}

.header_layout_verification {
  display: flex;
  align-items: center;
  color: $darkBlue;
  font-family: "Nunito";
  margin-bottom: 3vh;

  img {
    width: 7vw;
    margin-right: 3vw;
  }
}

.verification-paragraph {
  font-size: 4vw;
  color: grey;
  margin: 1vw 0 2vw 0;
}

.steps-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4vh;
  margin-bottom: 2vh;

  .circle {
    background-color: white;
    border: 3px solid rgb(216, 216, 216);
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;

    .smallCircle {
      background-color: rgb(216, 216, 216);
      height: 12px;
      width: 12px;
      border-radius: 7px;
    }
  }

  .current-circle {
    border: 3px solid $primaryColorHover;

    .smallCircle {
      background-color: $primaryColorHover;
    }
  }

  i {
    font-size: 100%;
    color: $primaryColorHover;
  }

  .line {
    width: 23%;
    background-color: rgb(216, 216, 216);
    height: 2px;
  }

  .current-line {
    background-color: $primaryColorHover;
  }
}

.verification_address {
  .document_preview {
    display: grid;
    grid-template-columns: auto 10% 10%;
    align-items: center;
    background: #f5f5f5;
    padding: 2vh 0vh 2vh 1vh;
    border-radius: 8px;

    h3 {
      font-weight: 500;
      color: #23232394;
      padding-right: 4vw;
    }
  }
}

.verification_address_finished {
  text-align: center;

  img {
    margin-bottom: 3vh;
  }

  h2 {
    text-align: center;
    color: #00000078;
    font-size: 4.7vw;
    font-weight: 600;
    margin-bottom: 2vh;
  }
}

.verification_address_form {
  .static_input {
    margin-top: 0;
    padding-left: 3vw;
    font-weight: 400;
    color: black;
    font-size: 4.1vw;
  }
}

.modal-cancelled-verf {
  height: 28vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2vh;
  width: 80vw;
  background: white;
  margin: auto;
  margin-bottom: 12vh;
  border-radius: 16px;
  h2 {
    margin-top: 2vh;
    font-family: "Nunito";
    font-size: 4.8vw;
    font-weight: bold;
  }

  p {
    font-size: 3.5vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
    color: grey;
  }

  .button {
    margin: auto;
  }
}

.swal2-html-container {
  margin: 0 !important;
}

.verification_cancelled {
  height: 80vh;
  width: 83vw;
  background: white;
  h2,
  p {
    color: red !important;
  }
}

.swal2-container {
  padding: 0 !important;
}

.swal2-modal {
  width: 100vw !important;
  background: transparent !important;
}

.footer-start-facial {
  position: absolute;
  bottom: 5vh;
  width: 84vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    margin-top: 4vw;
    color: $primaryColor;
    font-weight: 700;
  }
}

.summary-container {
  margin-top: 3vh;
}

.summary_item {
  align-items: center;
  border: 1px solid rgb(160, 160, 160);
  border-radius: 14px;
  color: black;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vh;
  padding: 0vw 3vw;
  height: 8vh;

  p {
    margin-top: 0 !important;
  }

  img {
    width: 10vw !important;
  }
}

.summary_item_true {
  color: rgb(160, 160, 160);
}
