.verification_facial_instructions {
  img {
    width: 60vw;
    margin-bottom: 5vh;
    margin-top: 5vh;
  }

  .zxac {
    text-align: center;
  }
}

.selfie_verification_main_content {
  .overlay {
    object-fit: cover;
  }
  .selfie_verification_content {
    position: absolute;
    height: 100vh;
    top: 0;
    width: 100vw;
    padding: 4vw 0vw 1vw 0vw;

    .header {
      padding: 0 4vw;
    }

    .verification-paragraph {
      position: absolute;
      bottom: 15%;
    }
    .footer_selfie {
      position: absolute;
      bottom: 5%;
      width: 90%;
      left: 5%;
    }
  }
}
